import React, { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingSpinner } from 'components/spinner/loading-spinner.component';
import { HIGHMARK_CONNECTION_SESSION_STORAGE_KEY } from 'common/sso-constants';

export const AuthSignOut = () => {
  useEffect(() => {
    StandaloneAuth.client.logout({
      federated: true,
      returnTo: window.location.origin,
    });
    sessionStorage.removeItem(HIGHMARK_CONNECTION_SESSION_STORAGE_KEY);
  }, []);

  return <LoadingSpinner />;
};
