import { useEffect, useState } from 'react';
import { useUrlParam } from '@leagueplatform/web-common';
import {
  QUERY_PARAM_KEYS,
  SSO_READY_LISTENER_TIMEOUT,
  SSO_URL_NAMES,
  WEBVIEW_MODAL_QUERY_PARAM_VALUE,
} from 'common/sso-constants';
import { LinkHandler, LinkHandlerClass } from 'utils/link-handler';
import { sendAuthTokenIfReady } from 'utils/post-message-auth-helpers';
import { logger } from 'utils/logger';
import { captureAuthedWebviewError } from 'utils/sentry-helpers';
import { getSsoUrl, getTrustedPostMessageOrigins } from 'utils/sso-url-helpers';
import { useHistory } from '@leagueplatform/routing';
import { LEAGUE_MODULE_NAMES, getModulePath } from '@leagueplatform/core';
import { isBrowserSafari } from 'utils/is-browser-safari';

const SSO_BENEFIT_ID_MAP = {
  [SSO_URL_NAMES.AMWELL]: '4843416d77656c6c0000000000000000',
  [SSO_URL_NAMES.SPRINGHEALTH]: '4843537072696e674865616c74682d45',
} as const;

const whitelistedSsoTargets = [
  SSO_URL_NAMES.SAPPHIRE,
  SSO_URL_NAMES.AMWELL,
  SSO_URL_NAMES.SPRINGHEALTH,
  SSO_URL_NAMES.KYRUUS,
];

export const useAuthedWebviewModal = (
  iframeRef: React.RefObject<HTMLIFrameElement> | null,
  linkHandler: LinkHandlerClass,
) => {
  const [url, setUrl] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [isClosedByUser, setIsClosedByUser] = useState<boolean>(false);
  const history = useHistory();

  const { urlParam } = useUrlParam(QUERY_PARAM_KEYS.modal);

  const onError = (caughtError: Error) => {
    setError(caughtError);
    captureAuthedWebviewError(caughtError, url);
  };

  const closeModal = () => {
    setIsClosedByUser(true);
    setError(null);
  };

  const resetToDefaults = () => {
    setUrl('');
    setIsClosedByUser(false);
  };

  useEffect(() => {
    if (urlParam === WEBVIEW_MODAL_QUERY_PARAM_VALUE) {
      const newUrl = linkHandler.lastPassedUrl;
      // go back if there is not a valid authed webview passed
      if (!newUrl) {
        history.goBack();
      }
      if (newUrl && !isClosedByUser) {
        setUrl(newUrl);
      }
      // handle navigation when user closes the webview
      if (isClosedByUser) {
        // go back in history until the search (`?modal=`) has been cleared; this is necessary due to how iFrames affect browser navigation
        if (history.location.search.length) {
          // since browsers handle iFrame navigation as a separate child, check how many steps back we need to go
          // window.top is the topmost window in the hierarchy of window objects
          const historyDiff = window.top?.history?.length
            ? history.length - window.top.history.length - 1
            : -1;
          history.go(historyDiff);
        } else {
          resetToDefaults();
        }
      }
    }
    // clean up
    return () => {
      if (!history.location.search.length) {
        resetToDefaults();
      }
    };
  }, [
    urlParam,
    linkHandler,
    isClosedByUser,
    history,
    history.location.search,
    history.length,
  ]);

  useEffect(() => {
    if (!isClosedByUser && url && iframeRef?.current) {
      const targetOrigin = new URL(url).origin;
      const messageHandler = async (event: MessageEvent) => {
        logger.log('Received postmessage event', event);

        try {
          const isAuthenticated = await sendAuthTokenIfReady(
            event,
            targetOrigin,
            iframeRef?.current?.contentWindow,
          );

          if (isAuthenticated) {
            setLoading(false);
          }
        } catch (err: any) {
          setLoading(false);
          onError(err instanceof Error ? err : new Error(err));
        }

        const action = event?.data?.action;

        const navigationTarget = event?.data?.navigate_to;

        // If event is not coming from a whitelisted origin or the src origin, ignore.
        if (
          event?.origin !== targetOrigin &&
          !getTrustedPostMessageOrigins().includes(event?.origin)
        ) {
          return;
        }

        if (navigationTarget) {
          if (whitelistedSsoTargets.includes(navigationTarget)) {
            if (isBrowserSafari()) {
              /**
               * This is a workaround to fix an issue only present within Safari.
               * - Calling `handleLink` doesn't work in Safari.  There is a restriction where you can't call window.open
               * when an async function.  Because we listen to the `postMessage` event and trigger the SSO code in
               * `linkHandler` wrapped within the async handler, it will consider it a
               * programmatically invoked window and not a user interaction.
               * - Don't call `closeModal` since it will trigger the `useEffect` rewriting the URL and pushing
               * it after what we push into history.  Since we redirect the modal doesn't need to be programmatically closed.
               */
              const WALLET_DETAILS_ROUTE = `${getModulePath(
                LEAGUE_MODULE_NAMES.wallet,
              )}`;

              switch (navigationTarget) {
                case SSO_URL_NAMES.SPRINGHEALTH: {
                  const springHealthBenefitUrl = `${WALLET_DETAILS_ROUTE}/${
                    SSO_BENEFIT_ID_MAP[SSO_URL_NAMES.SPRINGHEALTH]
                  }`;
                  history.push(springHealthBenefitUrl);
                  break;
                }
                case SSO_URL_NAMES.AMWELL: {
                  const amwellBenefitUrl = `${WALLET_DETAILS_ROUTE}/${
                    SSO_BENEFIT_ID_MAP[SSO_URL_NAMES.AMWELL]
                  }`;
                  history.push(amwellBenefitUrl);
                  break;
                }
                case SSO_URL_NAMES.SAPPHIRE: {
                  const careDiscoveryUrl = `${getModulePath(
                    LEAGUE_MODULE_NAMES.careDiscovery,
                  )}`;
                  history.push(careDiscoveryUrl);
                  break;
                }
                case SSO_URL_NAMES.KYRUUS: {
                  const careDiscoveryUrl = `${getModulePath(
                    LEAGUE_MODULE_NAMES.careDiscovery,
                  )}`;
                  history.push(careDiscoveryUrl);
                  break;
                }
                default: {
                  LinkHandler.handleLink(getSsoUrl(navigationTarget));
                  closeModal();
                }
              }
            } else {
              LinkHandler.handleLink(getSsoUrl(navigationTarget));
              closeModal();
            }
          } else {
            captureAuthedWebviewError(
              new Error(
                `Unknown navigation target received: ${navigationTarget}: ${JSON.stringify(
                  event.data,
                )}`,
              ),
              url,
            );
          }
        }

        if (action) {
          switch (action) {
            case 'close':
              closeModal();
              break;

            default:
              captureAuthedWebviewError(
                new Error(
                  `Unknown action received: ${action}: ${JSON.stringify(
                    event.data,
                  )}`,
                ),
                url,
              );
          }
        }
      };

      window.addEventListener('message', messageHandler);

      const timeout = setTimeout(() => {
        // If isLoading is still true, this means ready message wasn't received.
        // Then, set loading to false and set error state
        setLoading((loading) => {
          if (loading) {
            onError(
              new Error(
                'Could not send auth token, ready message not received from iframe.',
              ),
            );
          }
          return false;
        });
      }, SSO_READY_LISTENER_TIMEOUT);

      return () => {
        clearTimeout(timeout);
        window.removeEventListener('message', messageHandler);
      };
    }
    setLoading(true);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef, urlParam, url, isClosedByUser]);

  return {
    isOpen: Boolean(urlParam && url),
    url,
    closeModal,
    isLoading,
    error,
  };
};
